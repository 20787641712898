<template>
    <BaseContainer style="background: #D5D6D5; background: linear-gradient(to bottom right, #242424, #999);">
        <span slot="principal">
            <v-content>
                <v-container fluid justify-center align-center>
                    <div style="text-align: center; font-weight: bold; font-size: 18px; padding: 15px;">
                        Zaper Control
                    </div>
                    <v-row>
                        <v-col>
                            <v-btn
                                link
                                to="/empresas"
                                height="50"
                                width="100"
                            >Empresas</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                link
                                to="/usuarios"
                                height="50"
                                width="100"
                            >Usuários</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                link
                                to="/contatos"
                                height="50"
                                width="100"
                            >Leads</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <Card
                                v-model="activeCustomersCount"
                                label="Clientes ativos"
                                color="success"
                                icon="mdi-account"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <Card
                                v-model="inactiveCustomersCount"
                                label="Inadimplentes"
                                color="error"
                                icon="mdi-account-cancel"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-show="isDiretor">
                        <v-col cols="12" md="6">
                            <Card
                                v-model="getMrr"
                                label="MRR"
                                color="cyan accent-4"
                                icon="mdi-cash"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <Card
                                v-model="getArr"
                                label="ARR"
                                color="light-green accent-4"
                                icon="mdi-cash-multiple"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-show="isDiretor">
                        <v-col cols="12" md="6">
                            <Card
                                v-model="getValuation"
                                label="Valuation Estimado"
                                color="purple accent-2"
                                icon="mdi-diamond-stone"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <Card
                                v-model="getMeta"
                                label="Meta MRR 2024"
                                color="amber lighten-1"
                                icon="mdi-star"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <Card
                                v-model="getMetaCadastros"
                                label="Meta Cadastros Mês"
                                color="brown accent-2"
                                icon="mdi-chart-line"
                                :progress="getMetaCadastrosProgress"
                                progressColor="brown"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <Card
                                v-model="getMetaClientes"
                                label="Meta Clientes 2024"
                                color="purple lighten-1"
                                icon="mdi-bullseye-arrow"
                                :progress="getMetaClientesProgress"
                                progressColor="purple"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-show="isDiretor">
                        <v-col>
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Faturamento
                                </div>
                                <canvas id="revenueByWeek" :height="barChartHeight"></canvas>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Taxa de Conversão Mensal
                                </div>
                                <canvas id="conversaoMensal" :height="barChartHeight"></canvas>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Quantidade de Leads por Semana
                                </div>
                                <canvas id="leadsByWeek" :height="barChartHeight"></canvas>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="(funil, i) in funilVendas" :key="i">
                            <v-card class="pa-2" style="background-color: #222;">
                                <v-card-title class="justify-center">
                                    Mês {{ funil.month }}
                                </v-card-title>
                                <VueFunnelGraph
                                    :width="width"
                                    :height="height"
                                    :labels="labels"
                                    :values="[funil.num_leads, funil.num_prospects, funil.num_clients_paying]"
                                    :colors="colors"
                                    :sub-labels="subLabels"
                                    :direction="direction"
                                    :gradient-direction="gradientDirection"
                                    :animated="true"
                                    :display-percentage="true"
                                    :sub-label-value="subLabelValue"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card>
                                <v-card-title class="justify-center">
                                    Taxas de Conversão
                                </v-card-title>
                                <v-data-table
                                    fixed-header
                                    :headers="headers"
                                    :items="funilVendas"
                                    sort-by="id"
                                    :mobileBreakpoint="0"
                                    :height="450"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                >
                                    <template v-slot:item.leads_to_prospects="{ item }">
                                        {{ getTaxaConversao(item.num_leads, item.num_prospects) }}%
                                    </template>
                                    <template v-slot:item.prospects_to_clients="{ item }">
                                        {{ getTaxaConversao(item.num_prospects, item.num_clients_paying) }}%
                                    </template>
                                    <template v-slot:item.leads_to_clients="{ item }">
                                        {{ getTaxaConversao(item.num_leads, item.num_clients_paying) }}%
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Chart from 'chart.js';
import VueFunnelGraph from './FunnelChart';
import Card from './Card';
import { mapState } from 'vuex';

export default {
    name: 'Home',

    components: {
        BaseContainer,
        VueFunnelGraph,
        Card,
    },

    data: () => ({
        barCharts: {
            conversaoMensal: null,
            leadsByWeek: null,
            revenueByWeek: null,
        },
        loading: false,
        leadsByWeek: [],
        funilVendas: [],
        conversaoMensal: [],
        customerCountByStatus: [],
        revenueByWeek: [],
        mrr: 0,

        labels: ['Leads', 'Prospects', 'Clientes'],
        subLabels: [],
        colors: ['#21ffa2', '#217ca1'],
        direction: 'vertical',
        gradientDirection: 'horizontal',
        height: 280,
        width: 120,
        dataSetNum: 1,
        subLabelValue: 'percent',
        headers: [
            { text: 'Mês/Ano', value: 'month' },
            { text: 'Lead', value: 'num_leads' },
            { text: 'Prospects', value: 'num_prospects' },
            { text: 'Clientes', value: 'num_clients_paying' },
            { text: 'Taxa conversão Leads -> Prospects', value: 'leads_to_prospects' },
            { text: 'Taxa conversão Prospects -> Clientes', value: 'prospects_to_clients' },
            { text: 'Taxa conversão Leads -> Clientes', value: 'leads_to_clients' },
        ],
    }),

    mounted() {
        this.mountCharts();
        this.consultar();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        barChartHeight() {
            const w = this.$vuetify.breakpoint.width;
            if (w <= 576) {
                return '350';
            }
            if (w > 576 && w <= 768) {
                return '250';
            }
            if (w > 768 && w <= 992) {
                return '200';
            }
            if (w > 992) {
                return '100';
            }
            return '100';
        },

        activeCustomersCount() {
            const { count } = this.customerCountByStatus.find(e => e.ativo) || {};
            return count || 0;
        },

        inactiveCustomersCount() {
            const { count } = this.customerCountByStatus.find(e => !e.ativo) || {};
            return count || 0;
        },

        getMrr() {
            return 'R$' + this.formatPrice(this.mrr);
        },

        getArr() {
            return 'R$' + this.formatPrice(this.mrr * 12);
        },

        getValuation() {
            return 'R$' + this.formatPrice(this.mrr * 12 * 2.5);
        },

        getMeta() {
            return 'R$' + this.formatPrice(100000);
        },

        getMetaCadastros() {
            return 60;
        },

        getMetaClientes() {
            return 500;
        },

        getMetaCadastrosProgress() {
            const currentMonthData = this.funilVendas[0];

            if (!currentMonthData) {
                return 0;
            }

            const progress = (currentMonthData.num_prospects * 100) / this.getMetaCadastros;
            return progress;
        },

        getMetaClientesProgress() {
            const activeCustomers = this.customerCountByStatus.find(e => e.ativo);

            if (!activeCustomers) {
                return 0;
            }

            const progress = (activeCustomers.count * 100) / this.getMetaClientes;
            return progress;
        },

        isDiretor() {
            return this.usuario.tipo === 'diretor';
        },
    },

    methods: {
        consultar() {
            this.loading = true;

            this.$http.get('estatisticas/get-estatisticas')
                .then(resp => {
                    this.funilVendas = resp.data?.funilVendas || [];
                    this.leadsByWeek = resp.data?.leadsByWeek || [];
                    this.customerCountByStatus = resp.data?.customerCountByStatus || [];
                    this.revenueByWeek = resp.data?.revenueByWeek || [];
                    this.mrr = resp.data?.mrr || 0;

                    this.funilVendas.forEach(e => {
                        const data = {
                            mes_ano: e.month,
                            taxa_conversao: ((e.num_clients_paying * 100) / e.num_leads).toFixed(2),
                        };
                        this.conversaoMensal.push(data);
                    });
                    this.conversaoMensal.reverse();

                    this.gerarGraficos();
                    this.loading = false;
                })
                .catch(e => {
                    console.log(e)
                    alert('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
        },

        gerarGraficos() {
            this.barCharts.conversaoMensal.data = {
                labels: this.conversaoMensal.map(p => p.mes_ano),
                datasets: [
                    {
                        label:  'Contratações',
                        data: this.conversaoMensal.map(p => +p.taxa_conversao),
                        backgroundColor: Array.from({length: 31}, () => '#2ECC71'),
                    }
                ]
            };
            this.barCharts.conversaoMensal.update();

            this.barCharts.leadsByWeek.data = {
                labels: this.leadsByWeek.map(p => p.week),
                datasets: [
                    {
                        label:  'Semana',
                        data: this.leadsByWeek.map(p => +p.num_leads),
                        backgroundColor: Array.from({length: 31}, () => '#2ECC71'),
                    }
                ]
            };
            this.barCharts.leadsByWeek.update();

            this.barCharts.revenueByWeek.data = {
                labels: this.revenueByWeek.map(p => p.month),
                datasets: [
                    {
                        label:  'Faturamento',
                        data: this.revenueByWeek.map(p => +p.total),
                        backgroundColor: Array.from({length: 31}, () => '#2ECC71'),
                    }
                ]
            };
            this.barCharts.revenueByWeek.update();
        },

        mountCharts() {
            const $ = el => document.getElementById(el);

            this.barCharts.conversaoMensal = new Chart($('conversaoMensal'), {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [],
                },
                options: {
                    // responsive: false,
                    // maintainAspectRatio: false,
                   scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                    max: 10,
                                    callback: v => v + '%'
                                },
                            },
                        ],
                    },
                    tooltips: {
                        callbacks: {
                            label: v => v.yLabel + '%'
                        },
                    },
                },
            });

            this.barCharts.leadsByWeek = new Chart($('leadsByWeek'), {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [],
                },
                options: {
                    // responsive: false,
                    // maintainAspectRatio: false,
                   scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                },
                            },
                        ],
                    },
                },
            });

            this.barCharts.revenueByWeek = new Chart($('revenueByWeek'), {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [],
                },
                options: {
                    // responsive: false,
                    // maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                    callback: v => 'R$' + this.formatPrice(v),
                                },
                            },
                        ],
                    },
                    tooltips: {
                        callbacks: {
                            label: v => 'R$' + this.formatPrice(v.yLabel),
                        },
                    },
                },
            });
        },

        getTaxaConversao(n1, n2) {
            return this.formatPrice((n2 / n1) * 100);
        },
    },
}
</script>

<style scoped>

/* CARDS  */
.class-on-data-table table {
    table-layout: fixed;
}

.br-5 {
    border-radius: 5px !important;
}

.monetary {
    font-weight: 600;
    font-size: 26px;
}

.col-sm-6  {
    padding: 5px;
}

.col-12 {
    padding: 5px 10px 5px 10px;
}

.v-card {
    padding: 0 15px 0 15px;
}

.text-overline {
    font-weight: 600;
    letter-spacing: 0.1em !important;
}
</style>